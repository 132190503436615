import { breakpoints, debounce } from "./utilities.js";

let firstRun = true;
let touch = 'ontouchstart' in window;

const init = function() {
	$('.js-help-pin.js-internal:not(.js-help-pin-done)').each(function() {
		var link = $(this);
		var allow = true;
		var help = $(link.attr('href'));
		var anchored = help.is('.anchored') ? true : false;
		var fadeAnimationTime = 250;

		// launch click
		link.off('click').on('click', function() {
			show(link, allow, help, anchored);
			return false;
		});

		// hover
		if (link.hasClass('js-help-pin-hover')) {


			link.off('mouseenter').on('mouseenter', function() {

				if (touch) {
					return;
				}
				show(link, allow, help, anchored, true);
			});

			link.off('mouseleave').on('mouseleave', function() {
				if (touch) {
					return;
				}
				if (!allow) return false;
				allow = true;
				help.hide();
			});

		}

		// resize
		window.site.win.on('resize', debounce(function() {
			if (anchored && window.matchMedia('(min-width:' + breakpoints.large + 'px)').matches) {
				help.css({
					'left': -(link.parent().offset().left - link.offset().left),
					'top': -(link.parent().offset().top - link.offset().top)
				});
			}
			else if (anchored) {
				help.css({
					'left': 0,
					'top': 0
				});
			}
		}))

		// close
		help.find('.js-close').on('click', function() {
			if (!allow) return false;
			allow = false;
			help.fadeOut(250, function() {
				allow = true;
			});
		});

		// anywhere click
		window.site.body.on('click', function(e) {
			if ($(e.target).closest('.m-help-overlay').length === 0 && allow) {
				allow = false;
				help.fadeOut(fadeAnimationTime, function() {
					allow = true;
				});
			}
		});

		// done
		link.addClass('js-help-pin-done');
	});

	if (firstRun) {
		firstRun = false;
		if ($('.js-help-pin').length) {
			detectIfTouch();
		}
	}

}

const detectIfTouch = function() {
	window.site.doc.on('touchstart', function() {
		touch = true;
	});

	window.site.doc.on('mousemove', function() {
		touch = false;
	});
}

const show = function(link, allow, help, anchored, showImmediately) {

	if (!allow) return false;
	allow = false;

	if (help.is(':visible') || showImmediately) {
		help.show();
		allow = true;
		return;
	}

	if (anchored && window.matchMedia('(min-width:' + breakpoints.large + 'px)').matches) {
		help.css({
			'left': -(link.parent().offset().left - link.offset().left),
			'top': -(link.parent().offset().top - link.offset().top)
		});
	}
	else if (anchored) {
		help.css({
			'left': 0,
			'top': 0
		});
	}

	help.fadeToggle(250, function() {
		allow = true;
	});

}

export default init;
